import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './ListEntry.module.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { slug as slugFn } from '@mangoart/gatsby-ui/helper/Slugger';

function ListEntry({ entry }, awardSite) {
  const { image: teaserImage, date, title, teaser, gallery, externalLink } = entry;

  const image = getImage(teaserImage) || getImage(gallery[0].image);

  const { t, i18n } = useTranslation();
  const slug = slugFn(title);

  const linkToArticle = externalLink
    ? externalLink
    : i18n.language === 'de'
    ? `/aktionen/${slug}`
    : `/en/aktionen/${slug}`;

  return (
    <div
      className={styles.content}
      onClick={(e) => {
        // navigateTo(linkToArticle, extraPath, 'de');
        e.preventDefault();
        if (externalLink) {
          window.open(linkToArticle, '_blank');
        } else {
          navigate(linkToArticle);
        }
      }}
    >
      <GatsbyImage image={image} alt={title} className={styles.image} imgStyle={{ objectFit: 'cover' }} />
      <div className={styles.title}>{title}</div>
    </div>
  );
}

export default ListEntry;
