import React from 'react';

import ListEntry from './ListEntry';

import * as styles from './List.module.css';

function sortByDate(entry1, entry2) {
  return new Date(entry2.node.date).getTime() - new Date(entry1.node.date).getTime();
}

function filterExpired(node) {
  if (node.node.expirationdate <= 0) {
    return node;
  }

  return new Date(node.node.expirationdate) < new Date().getTime() ? null : node;
}

function AktionenList({ entries, awardSite }) {
  return (
    <div className={styles.content}>
      {entries
        .sort(sortByDate)
        .filter(filterExpired)
        .map(({ node }) => {
          return <ListEntry key={node.id} entry={node} awardSite={awardSite} />;
        })}
    </div>
  );
}

export default AktionenList;
